.pill {
    background-color: #a4b0be;
    display: inline-block;
    padding: 4px 14px;
    border-radius: 14px;
    color: #fff;
}

.active {
    background-color: #70a1ff;
}