.pokemon-card {
    padding: 8px;
}

.pokemon-card:hover {
    animation-name: bounce;
    animation-duration: 200ms;
    animation-timing-function: ease;
}

.pokemon-card a{
    display: block;
}

.pokemon-card a:hover{
    
}

figure img{
    width: 100%;
}

.loading-image {
    display: block;
    margin: auto;
}

.pokemon-info {
    padding-left: 12px;
}

.pokemon-info h5{
    color: #313131;
    text-transform: none;
    font-size: 1.5rem;
    margin-top: 12px;
    margin-bottom: 10px;
}

figure {
    background: #f1f2f6;
    display: block;
    position: relative;
    border-radius: 5px;
    width: 100%;
    min-height: 100px;
}

.release-pokemon-button {
    position: absolute;
    right: 0;
    background-color: #ff4757;
    color: #fff;
    max-width: 20%;
    height: 40px;
    overflow: hidden;
    white-space: nowrap
}

.release-pokemon-button::before {
    content: 'X';
}

.release-pokemon-button:hover {
    color: #fff;
    animation-name: expand;
    animation-duration: 300ms;
    animation-fill-mode: both;
    animation-timing-function: ease;
}

.release-pokemon-button:hover::before {
    content: 'Release Pokemon';
}

@keyframes bounce {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-10px); }
    100% { transform: translateY(0); }
}

@keyframes expand {
    0% {
        max-width: 20%;
    }

    100% {
        max-width: 100%;
    }
}