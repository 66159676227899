/* .pokemon-detail {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 50px;
} */

.column {
    flex-basis: 50%;
    padding: 20px;
}

.pokemon-name {
    text-align: center;
    font-size: 36px;
    color: #212121;
}

.pokemon-profile {
    position: relative;
    text-align: center;
}

.pokemon-image {
    display: inline-block;
    margin: auto;
}

.pokemon-image img {
    width: 241px;
}

.pokemon-specs {
    background-color: #1e90ff;
    border-radius: 10px;
    padding: 20px;
}

.spec-item {
    display: flex;
    font-size: 20px;
}

.spec-item:not(:last-child) {
    margin-bottom: 16px;
}

.spec-name {
    color: #fff;
    flex-grow: 1;
    font-weight: 600;
}

.spec-value {
    color: #212121;
}

.pokemon-stats {
    margin-top: 20px;
    background-color: #a4a4a4;
    border-radius: 10px;
    padding: 20px;
    color: #fff;
    font-size: 20px;
}

.pokemon-stats h3 {
    font-size: 25px;
    font-weight: 600;
}

.pokemon-stats-wrapper {
    margin-top: 15px;
}

.pokemon-stats-item {
    display: flex;
    margin-bottom: 14px;
    justify-content: space-between;
}

.pokemon-stats-item span {
    /* flex-basis: 50%; */
}

.pokemon-stats {
    flex-grow: 1;
}

.pokemon-stats-value {
    color: #212121;
}

.pokemon-type {
    margin-top: 20px;
}

.pokemon-type h3{
    margin-bottom: 14px;
    font-size: 25px;
    font-weight: 600;
}

.pokemon-type-name {
    display: inline-block;
    margin-right: 8px;
    background-color: #ff4757;
    color: #fff;
    padding: 6px 12px;
    border-radius: 10px;
    width: 30%;
    text-align: center;
    font-size: 18px;
}

.pokemon-moves {
    margin-top: 20px;
}

.pokemon-moves h3 {
    margin-bottom: 14px;
    font-size: 25px;
    font-weight: 600;
}

.pokemon-moves-wrapper {
    text-align: center;
    max-height: 350px;
    overflow: auto;
}

.move {
    margin-bottom: 10px;
    margin-right: 10px;
}

.catchButton {
    min-width: 200px;
    border-color: #ff4757;
    background-color: #ff4757;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    border-radius: 30px;
    box-shadow: none;
    position: fixed;
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 19;
}

.catchButton:hover {
    background-color: #ff6b81;
    color: #fff;
}

.failed-toast {
    background-color: #57606f;
    position: fixed;
    opacity: 0;
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.show-failed-toast {
    animation-name: fadeInUp;
    animation-duration: 3s;
    animation-fill-mode: both;
    animation-timing-function: ease;
}

.loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes fadeInUp {
    0% {
        bottom: 14px;
        width: 0;
        height: 0;
    }

    8% {
        bottom: 65px;
        opacity: 1;
        height: 70px;
        width: 70px;
    }
    
    85% {
        bottom: 65px;
        opacity: 1;
        height: 70px;
        width: 70px;
    }

    100% {
        bottom: 14px;
        width: 0;
        height: 0;
    }
}